import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./layout.html";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { initializeAuth, logout, authManager } from "./auth-helper";

// Initialize Application Insights
let appInsights = null;

const initAppInsights = async (currentUserName) => {
  if (process.env.APP_INSIGHTS_CONNECTION_STRING) {
    appInsights = new ApplicationInsights({
      config: {
        connectionString: process.env.APP_INSIGHTS_CONNECTION_STRING,
      },
    });
    appInsights.loadAppInsights();
    appInsights.setAuthenticatedUserContext(currentUserName);
    appInsights.trackPageView();
  } else {
    console.warn("Application Insights connection string is missing.");
  }
};

// Construct routes and applications
const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

// Register Single-SPA applications
const registerSpaApp = async (user, auth) => {
  const authUser = {
    name: `${user?.family_name || ""} ${user?.given_name || ""}`.trim() || user?.nickname || user?.name || user?.username,
    picture: user?.picture || (user?.localAccountId ? `https://arp.mantu.com/aBook/Telecom/GetEmployeeThumbnail/${user?.localAccountId}` : ""),
    email: user?.["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"] || user?.email || user?.username || "",
    nameidentifier: user?.nameidentifier || user?.["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] || "no-user",
  };

  if (authUser.nameidentifier) {
    await initAppInsights(authUser.nameidentifier);
  }

  applications.forEach((props) => {
    // Add custom properties accessible to sub-applications
    props.customProps = {
      ...props.customProps,
      examplePropRootConfig: "Example data from root Single SPA",
      authUser,
      auth,
      authManager,
      ...(appInsights ? { appInsights } : {}), // Add appInsights if initialized
    };
    registerApplication(props);
  });
};

// Initialize authentication and register applications
initializeAuth(registerSpaApp);
layoutEngine.activate();

// Start Single-SPA
start({
  urlRerouteOnly: true,
});

// Global event listeners for micro frontends
window.addEventListener("signout", () => {
  console.log("Clearing localStorage...");
  localStorage.clear();
  console.log("Logging out...");
  logout();
});

window.addEventListener(
  "single-spa:before-routing-event",
  ({ detail: { oldUrl, newUrl } }) => {
    appInsights?.trackPageView({
      name: `Navigation from ${oldUrl} to ${newUrl}`,
      uri: newUrl,
    });
  }
);
